import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/SEO"

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost

    
    return (
      <Layout>
          <SEO title={ post.yoast_meta.title }/>

          <div className="main-content">
            <article>
                <h1 className="title"> {post.title}</h1>
                <small className="meta">{post.date}</small>
                <div className="summary" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                <div className="user-content" dangerouslySetInnerHTML={{ __html: post.content }} />
            </article>
          </div>

      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
        title
        excerpt
        content
        date(formatString: "MMMM DD, YYYY")
        yoast_meta{
          title
        }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`